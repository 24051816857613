<template>
	<v-app-bar
		app
		clipped-right
		elevation="0"
		:style="style"
	>
		<v-row align="center" justify="space-between">
			<v-col cols="2" class="mt-1">
				<span>
					<v-img
						v-if="whiteLabelLogoExists"
						:src="whiteLabelLogo"
						contain
						max-width="200"
						max-height="48"
					/>
					<span v-else class="mt-2">
						<ChamaileonLogo />
					</span>
				</span>
			</v-col>
			<v-col cols="2" />
			<v-col cols="4" class="d-flex align-center justify-center">
				<ReviewHeaderDeviceButtons
					@update="setActiveDevice"
				/>
			</v-col>
			<v-col cols="4" class="text-right">
				<ReviewHeaderStatus v-if="!isSmallDevice" class="ml-auto" />
				<v-btn
					v-else
					icon
					elevation="0"
					:title="$t('review.openComments')"
					@click="reviewStore.isBottomSheetOpen = true"
				>
					<v-icon color="primary">
						mdi-comment-outline
					</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<ReviewBottomSheet />
	</v-app-bar>
</template>

<script>
import { useReviewStore } from "@/store/reviewStore";
import { useWhitelabelStore } from "@/store/whitelabelStore";
import { mapStores } from "pinia";

export default {
	name: "ReviewHeader",
	data() {
		return {
			activeDevice: "mobile",
		};
	},
	computed: {
		...mapStores(useReviewStore, useWhitelabelStore),
		whiteLabelLogoExists() {
			if (this.whitelabelStore?.whitelabelExists && this.whitelabelStore.isCurrentValid && !this.whitelabelStore.isDefault) {
				return this.whitelabelStore.current.logoUrl;
			} else {
				return false;
			}
		},
		whiteLabelLogo() {
			return this.whitelabelStore?.current.logoUrl;
		},
		isSmallDevice() {
			return this.$vuetify.breakpoint.width < 768;
		},
		style() {
			return {
				"background-color": !this.whitelabelStore.isDefault ? "var(--v-header-base) !important" : "#FAFAFA !important",
				"border-bottom": !this.whitelabelStore.isDefault ? "1px solid var(--v-accent-base) !important" : "1px solid #E5E5E5 !important",
			};
		},
	},
	methods: {
		setActiveDevice(device) {
			this.reviewStore.activeDevice = device;
		},
	},
};
</script>
