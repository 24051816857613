import Vue from "vue";
import { defineStore } from "pinia";
import chamaileonFrontendModules from "@edmdesigner/chamaileon-frontend-modules";
import createCrudConnector from "@/utils/connectors/createCrudConnector";
import createApiConnectors from "@/utils/connectors/createApiConnectors";
import { getBaseRoute } from "@/utils/connectors/helpers";
import patchFormatter from "@/utils/patchFormatter";
import i18n from "@/plugins/i18n";
import vuetify from "@/plugins/vuetify";

const logger = chamaileonFrontendModules.storeModules.logger;

const reviewConnector = createCrudConnector({
	currentId: "reviewHash",
	methods: ["getOne", "patchOne"],
	route: {
		base: "folders-eu",
		path: "/review",
	},
});

const commentConnector = createCrudConnector({
	currentId: "commentId",
	methods: ["list", "createOne", "patchOne", "deleteOne"],
	route: {
		base: "folders-eu",
		path: "/review/:reviewId/comment",
	},
});

const collaboratorConnector = createCrudConnector({
	currentId: "collaboratorId",
	methods: ["list", "patchOne", "updateOne"],
	route: {
		base: "folders-eu",
		path: "/review/:reviewId/collaborator",
	},
});

const apiConnector = createApiConnectors([
	{
		key: "getReviewBaseUrl",
		method: "GET",
		route: {
			base: "folders-eu",
			path: "/review/:reviewId/baseUrl",
		},
	},
	{
		key: "updateThread",
		method: "PUT",
		route: {
			base: "folders-eu",
			path: "/review/:reviewId/thread/:threadId",
		},
	},
]);

export const useReviewStore = defineStore("review", {
	state: () => ({
		current: {},
		comments: [],
		collaborators: [],

		selectedEid: null,
		selectedComment: null,
		clickOrigin: "",
		collaboratorId: "",

		reviewStatus: null,
		activeDevice: "mobile",

		showApproveConfirmation: false,
		showApprove: false,
		showDismissConfirmation: false,

		threadIdToReopen: null,
		newComment: null,
		isBottomSheetOpen: false,
		isLoading: false,
	}),
	actions: {
		selectElement(domElement, eid) {
			this.selectedEid = eid;
			this.selectedComment = this.comments.filter(el => !el?.resolved).find(el => el.eid === eid)?._id;
			const clickOrigin = this.clickOrigin;
			this.clickOrigin = "";

			if (vuetify?.framework?.breakpoint?.width < 768) this.isBottomSheetOpen = true;

			const reviewContainer = document.querySelector("#review-container");
			const reviewDocument = reviewContainer.contentWindow.document;
			reviewDocument.querySelectorAll(".active-element").forEach(el => el.classList.remove("active-element"));
			domElement.classList.add("active-element");
			if (clickOrigin === "comment") {
				domElement.scrollIntoView({ behavior: "smooth" });
			} else {
				const threadContainer = document.querySelector(`#thread-eid-${eid}`);
				if (threadContainer) {
					threadContainer.scrollIntoView({ behavior: "smooth" });
				} else {
					setTimeout(() => {
						const addNewThreadContainer = document.querySelector("#thread-new");
						if (addNewThreadContainer) addNewThreadContainer.scrollIntoView({ behavior: "smooth" });
					});
				}
			}
		},
		unselectElement() {
			this.selectedEid = null;
			this.selectedComment = null;

			const reviewContainer = document.querySelector("#review-container");
			const reviewDocument = reviewContainer.contentWindow.document;
			reviewDocument.querySelectorAll(".active-element").forEach(el => el.classList.remove("active-element"));
		},
		async getReviewBaseUrl(reviewId) {
			const { result } = await apiConnector.getReviewBaseUrl({
				ids: { reviewId },
			});
			return result?.reviewBaseUrl;
		},
		async loadOne(reviewHash) {
			try {
				const { result: item } = await reviewConnector.getOne({
					ids: { reviewHash },
				});

				this.current = item;
			} catch (error) {
				const translationExists = i18n.te(`errorMessages.${error.message}`);
				const errorCode = translationExists ? error.message : "networkError";
				logger().logError({
					id: Date.now(),
					handled: true,
					code: error.message,
					source: "backend",
					stack: error,
					notification: i18n.t(`errorMessages.${errorCode}`),
					target: { type: "flashToast", timeout: 4000 },
				});
			}
		},
		// comment actions
		async loadComments() {
			try {
				const { result } = await commentConnector.list({
					ids: { reviewId: this.current._id },
				});

				this.comments = result;
			} catch (error) {
				const translationExists = i18n.te(`errorMessages.${error.message}`);
				const errorCode = translationExists ? error.message : "networkError";
				logger().logError({
					id: Date.now(),
					handled: true,
					code: error.message,
					source: "backend",
					stack: error,
					notification: i18n.t(`errorMessages.${errorCode}`),
					target: { type: "flashToast", timeout: 4000 },
				});
			}
		},
		async createComment(body) {
			this.isLoading = true;
			try {
				await commentConnector.createOne({
					ids: { reviewId: this.current._id, commentId: "" },
					body,
				});

				logger().logSuccess({
					id: Date.now(),
					handled: true,
					source: "backend",
					notification: i18n.t("notification.comment.create"),
					target: { type: "flashToast", timeout: 4000 },
				});

				this.unselectElement();
				await this.loadComments();
			} catch (error) {
				const translationExists = i18n.te(`errorMessages.${error.message}`);
				const errorCode = translationExists ? error.message : "networkError";
				logger().logError({
					id: Date.now(),
					handled: true,
					code: error.message,
					source: "backend",
					stack: error,
					notification: i18n.t(`errorMessages.${errorCode}`),
					target: { type: "flashToast", timeout: 4000 },
				});
			}
			this.isLoading = false;
		},
		async updateComment(threadId, _id, data) {
			this.isLoading = true;
			try {
				const oldData = this.comments.find(el => el._id === threadId).comments.find(el => el._id === _id);
				const { body } = patchFormatter(data, oldData);

				await commentConnector.patchOne({
					ids: { reviewId: this.current._id, commentId: _id },
					body,
				});

				logger().logSuccess({
					id: Date.now(),
					handled: true,
					source: "backend",
					notification: i18n.t("notification.comment.edit"),
					target: { type: "flashToast", timeout: 4000 },
				});

				this.unselectElement();
				await this.loadComments();
			} catch (error) {
				const translationExists = i18n.te(`errorMessages.${error.message}`);
				const errorCode = translationExists ? error.message : "networkError";
				logger().logError({
					id: Date.now(),
					handled: true,
					code: error.message,
					source: "backend",
					stack: error,
					notification: i18n.t(`errorMessages.${errorCode}`),
					target: { type: "flashToast", timeout: 4000 },
				});
			}
			this.isLoading = false;
		},
		async deleteComment(_id) {
			try {
				await commentConnector.deleteOne({
					ids: {
						reviewId: this.current._id,
						commentId: _id,
					},
				});

				this.comments = this.comments.filter(el => el._id !== _id);
			} catch (error) {
				const translationExists = i18n.te(`errorMessages.${error.message}`);
				const errorCode = translationExists ? error.message : "networkError";
				logger().logError({
					id: Date.now(),
					handled: true,
					code: error.message,
					source: "backend",
					stack: error,
					notification: i18n.t(`errorMessages.${errorCode}`),
					target: { type: "flashToast", timeout: 4000 },
				});
			}
		},
		// collaborator actions
		async loadCollaborators() {
			try {
				const { result } = await collaboratorConnector.list({
					ids: { reviewId: this.current._id },
				});

				this.collaborators = result;
			} catch (error) {
				const translationExists = i18n.te(`errorMessages.${error.message}`);
				const errorCode = translationExists ? error.message : "networkError";
				logger().logError({
					id: Date.now(),
					handled: true,
					code: error.message,
					source: "backend",
					stack: error,
					notification: i18n.t(`errorMessages.${errorCode}`),
					target: { type: "flashToast", timeout: 4000 },
				});
			}
		},
		async updateCollaborator({ _id, data, feedbackText }) {
			try {
				const oldData = this.collaborators.find(el => el._id === _id);
				const { body } = patchFormatter(data, oldData);

				await collaboratorConnector.patchOne({
					ids: { reviewId: this.current._id, collaboratorId: _id },
					body: { actions: body.actions, feedbackText },
				});

				logger().logSuccess({
					id: Date.now(),
					handled: true,
					source: "backend",
					notification: i18n.t(`notification.approve.${data.status}`),
					target: { type: "flashToast", timeout: 4000 },
				});

				await this.loadCollaborators();
			} catch (error) {
				const translationExists = i18n.te(`errorMessages.${error.message}`);
				const errorCode = translationExists ? error.message : "networkError";
				logger().logError({
					id: Date.now(),
					handled: true,
					code: error.message,
					source: "backend",
					stack: error,
					notification: i18n.t(`errorMessages.${errorCode}`),
					target: { type: "flashToast", timeout: 4000 },
				});
			}
		},
		async updateCollaboratorNotification({ _id, value }) {
			try {
				await collaboratorConnector.updateOne({
					ids: { reviewId: this.current._id, collaboratorId: _id },
					body: { hasViewedAfterEdit: value },
				});
			} catch (error) {
				const translationExists = i18n.te(`errorMessages.${error.message}`);
				const errorCode = translationExists ? error.message : "networkError";
				logger().logError({
					id: Date.now(),
					handled: true,
					code: error.message,
					source: "backend",
					stack: error,
					notification: i18n.t(`errorMessages.${errorCode}`),
					target: { type: "flashToast", timeout: 4000 },
				});
			}
		},
		// thread actions
		async updateThread(_id, resolved) {
			try {
				await apiConnector.updateThread({
					ids: {
						reviewId: this.current._id,
						threadId: _id,
					},
					body: {
						resolved,
						collaboratorId: this.collaboratorId,
					},
				});

				logger().logSuccess({
					id: Date.now(),
					handled: true,
					source: "backend",
					notification: i18n.t(`notification.thread.${resolved ? "resolve" : "reopen"}`),
					target: { type: "flashToast", timeout: 4000 },
				});

				this.unselectElement();
				await this.loadComments();
			} catch (error) {
				const translationExists = i18n.te(`errorMessages.${error.message}`);
				const errorCode = translationExists ? error.message : "networkError";
				logger().logError({
					id: Date.now(),
					handled: true,
					code: error.message,
					source: "backend",
					stack: error,
					notification: i18n.t(`errorMessages.${errorCode}`),
					target: { type: "flashToast", timeout: 4000 },
				});
			}
		},
	},
	getters: {
		avatarUrl() {
			return (userId) => {
				const baseUrl = getBaseRoute(Vue.prototype.$chamaileon.environmentConfig, "users");
				return `${baseUrl}/user/${userId}/avatar`;
			};
		},
		avatarColor() {
			return (_id) => {
				// this is used to always get the same color for the same user
				const vuetifyColors = [
					"red",
					"pink",
					"purple",
					"deep-purple",
					"indigo",
					"blue",
					"light-blue",
					"cyan",
					"teal",
					"green",
					"light-green",
					"lime",
					"yellow",
					"amber",
					"orange",
					"deep-orange",
					"brown",
					"blue-grey",
					"grey",
				];

				let hash = 0;
				for (let i = 0; i < _id.length; i++) {
					hash = ((hash * 31) + _id.charCodeAt(i)) % vuetifyColors.length;
				}

				return vuetifyColors[hash];
			};
		},
		name() {
			return ({ id, collaborator }) => {
				const extended = collaborator || this.collaborators.find(el => el._id === id);
				return extended?.userId ? (extended.displayName || `@${extended.userName}`) : extended.email;
			};
		},
	},
});
